.dc-html-content-syntax-highlight pre {
  background-color: #002b36;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 1.5em;
  font-family: monospace;
}

code,
pre,
mark {
  background-color: #efeff5;
  mix-blend-mode: normal;
}

.dc-html-content-syntax-highlight pre code {
  white-space: pre-wrap;
}

.dc-image-inline {
  display: inline-block !important;
}

.dc-image-small {
  max-width: 100%;
	width: 15%;
	height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dc-image-medium {
  max-width: 100%;
	width: 50%;
	height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dc-image-large {
  max-width: 100%;
	width: 80%;
	height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dc-image-full-width {
  max-width: 100%;
	width: 100%;
	height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dc-image-left {
  display: flex;
}

.dc-image-center {
  display: flex;
  margin: auto;
  cursor: pointer;
}

.dc-image-right {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
}

.dc-image-lightbox {
  display: flex;
  cursor: pointer;
}

/* Lightbox container (hidden by default) */
.lightbox {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); // Black background with 80% opacity
  text-align: center;
  overflow: auto;
}

.lightbox:target {
  display: block;
}

.lightbox img {
  max-width: 80%;
  max-height: 80%;
  margin-top: 7%;
}

.dc-lightbox-close {
  border-bottom-color: transparent; // Get around global <a> styles
  text-decoration: none;
  color: #fff;
  font-size: 2em;
  position: absolute;
  top: 20px;
  right: 40px;
}
